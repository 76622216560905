<template>
  <div class="header">
    <div class="header_wrapper">
      <div class="header_left">
        <div class="logo">
          <span class="logo_icon"></span>
          <div class="logo_info">
            <h1>壹直播</h1>
          </div>
        </div>
        <div class="header_tab">
          <span :class="{ active: tab == 'index' }" @click="$router.push('/')"
            >首页</span
          >
          <span
            :class="{ active: tab == 'room' }"
            @click="$router.push('myRoom')"
            >直播</span
          >
        </div>
      </div>
      <div class="header_right" v-if="isShowUser <= 0">
        <div class="user_photo">
          <img src="@/assets/image/pc_home/user_photo.png" alt="" />
        </div>
        <span class="login" @click="openLogin()">登录</span>
        <i>&nbsp;&nbsp;/&nbsp;&nbsp; </i>
        <span class="resgister" @click="openLogin()">注册</span>
      </div>
      <div class="header_userInfo" v-else>
        <div class="userInfo_img">
          <img
            :src="
              userInfoData.logo ||
              require('@/assets/image/pc_home/use_icon.png')
            "
            alt=""
          />
        </div>
        <span class="userInfo_name">{{ userInfoData.nickName }}</span>
        <i class="userInfo_icon"></i>
        <div class="user_detail">
          <i class="user_detail_top_icon"></i>
          <div class="user_detail_wrapper">
            <div class="user_detail_content">
              <div class="user_detail_img">
                <img
                  :src="
                    userInfoData.logo ||
                    require('@/assets/image/pc_home/use_icon.png')
                  "
                  alt=""
                />
                <div class="user_detail_leval">
                  <img
                    :src="
                      require(`@/assets/image/pc_home/vip_${this.userInfoData.vipInfo.level}.png`)
                    "
                    alt=""
                  />
                </div>
              </div>
              <span class="username">{{ userInfoData.nickName }}</span>
              <div class="login_out" @click="exitState">登出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="47.4609375vh"
      top="25vh"
      :close-on-click-modal="false"
      @close="handleClose"
      :lock-scroll="false"
      class="login"
    >
      <div class="content">
        <h1 class="login_title">登录参与聊互动赠送礼物</h1>
        <el-input
          v-model="phone"
          size="small"
          clearable
          placeholder="请输入手机号"
          maxlength="11"
          class="phone_input"
        >
          <template slot="prepend">+86</template>
        </el-input>
        <div class="code_wrapper">
          <el-input
            v-model="sms"
            size="small"
            clearable
            placeholder="请输入验证码"
            maxlength="6"
            class="code_input"
          >
            <template slot="prepend">
              <i class="code_icon"></i>
            </template>
          </el-input>
          <el-button
            @click="sendVerifycode()"
            :disabled="!disabledCodeBtn"
            class="sendVerifycode"
            :class="{ c_c: codeText != '获取验证码' }"
          >
            {{ codeText }}
          </el-button>
        </div>
        <div v-show="imgCode">
          <el-input
            placeholder="请输入验证码"
            maxlength="4"
            size="small"
            v-model="graphCode"
            clearable
            class="verify_input"
          >
            <template slot="append">
              <img
                :src="identifyCodeSrc"
                @click="getIdentifyingCode(true)"
                id="imgIdentifyingCode"
              />
            </template>
          </el-input>
        </div>
        <div class="login" @click="login">注册/登录</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import verificat from "@/assets/js/verification";
import { mapState } from "vuex";
// import * as Cookies from "js-cookie";
export default {
  inject: ["routePageInit"],
  data() {
    return {
      dialogVisible: false, // 是否显示登录弹窗
      disabledCodeBtn: true, //是否可以点击验证码
      phone: "", // 手机号
      sms: "", // 验证码
      codeText: "获取验证码",
      imgCode: false, //是否显示图形验证码
      graphCode: "", // 图形验证码字符串
      identifyCodeSrc: "", //图形验证码图片地址
      token: null,
      userHead: "",
      tab: "",
      errorList: [],
    };
  },
  mounted() {
    if (localStorage.token) {
      this.token = localStorage.token;
      this.getMemberInfo();
      // this.$store.commit("M_TOKEN", this.token);
    }
    this.userHead =
      localStorage.logo != "undefined" && localStorage.logo != "null"
        ? localStorage.logo
        : "";
    //获取个人信息
  },
  computed: {
    ...mapState({
      userInfoData: (state) => state.home.userInfoData,
    }),
    isShowUser() {
      return Object.keys(this.userInfoData).length;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.path == "/") {
          this.tab = "index";
        } else if (to.path == "/myRoom") {
          this.tab = "room";
        }
      },
    },
  },
  methods: {
    handleClose() {},
    exitState() {
      this.$confirm("确定退出登录吗", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        lockScroll: false,
        type: "warning",
      })
        .then(() => {
          this.$message({ type: "success", message: "退出成功" });
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push("/");
            localStorage.setItem("flag", true);
          }, 700);
          setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        })
        .catch((e) => {});
    },
    openLogin() {
      this.dialogVisible = true;
    },
    getMemberInfo() {
      //获取用户数据
      if (!this.token) {
        console.log("当前状态未登陆");
        return false;
      }
      this.$homeApi
        .getMemberInfo({
          apiName: "getMemberInfo",
          token: this.token,
        })
        .then((res) => {
          if (res.code == "9") {
            this.$store.commit("M_TOKEN", null);
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push("/");
            localStorage.setItem("flag", true);
            // Cookies.remove("token");
            this.$message({
              message: "登录状态已过期，请重新登录",
              type: "success",
            });
          } else {
            if (res.data && res.data.memberInfo) {
              this.$store.commit("SET_USER_DATA", res.data.memberInfo);
              this.$store.commit("M_TOKEN", this.token);
            }
          }
        });
    },
    async sendVerifycode() {
      // sendVerifycode() {
      if (!verificat.isMobile(this.phone)) {
        this.$message.error({ message: "请输入有效手机号!" });
      } else {
        try {
          this.getVerifyCode(this.phone);
          //  用手机号向后台换取验证码，发送成功则开始调用倒计时分方法
          this.countDown(60);
        } catch (error) {}
      }
    },
    // 向后台要验证码方法
    getVerifyCode(phone) {
      return this.$homeApi.smsCodeSend({
        apiName: "smsCodeSend",
        phone: phone,
        smsBizType: 1,
        verifyStr: this.verifyStr("smsCodeSend"),
      });
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.codeText = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.codeText = time + "s后获取";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    // 表单校验
    validate() {
      this.errorList = [];
      if (!verificat.isMobile(this.phone)) {
        this.errorList.push("请输入有效手机号!");
      }
      if (!verificat.verificatCode(this.sms)) {
        this.errorList.push("请输入有效验证码");
      }
      return this.errorList.length <= 0;
    },
    login() {
      if (this.validate()) {
        this.$homeApi
          .memberLogin({
            apiName: "memberLogin",
            graphCode: this.graphCode,
            loginObj: this.phone,
            loginType: 1,
            loginVerify: this.sms,
            verifyStr: this.verifyStr("memberLogin"),
          })
          .then((response) => {
            if (response.code == 8) {
              this.imgCode = true;
              this.$message.error({ message: response.msg });
              this.getIdentifyingCode();
            } else {
              // Cookies.set("token", response.data.loginToken, { expires: 30 });
              localStorage.token = response.data.loginToken;
              localStorage.memberId = response.data.memberId;
              localStorage.nickName = response.data.nickName;
              localStorage.logo = response.data.logo;
              localStorage.loginFlag = true;
              this.routePageInit();
              this.$store.commit("M_TOKEN", response.data.loginToken);
              this.$message({ type: "success", message: response.msg });
              this.dialogVisible = false;
            }
          });
      } else {
        for (let i in this.errorList) {
          setTimeout(
            () => this.$message.error({ message: this.errorList[i] }),
            500 * i
          );
        }
      }
    },
    /**
     * 窗口代码
     * @param {Boolean} bRefresh 是否刷新
     */
    getIdentifyingCode: function (bRefresh) {
      this.identifyCodeSrc =
        baseAddress +
        "/graph/imgCode?uniqueNo=" +
        localStorage.getItem("udid").toString() +
        "&timestamp=" +
        new Date().getTime();
      if (bRefresh) {
        this.identifyCodeSrc =
          baseAddress +
          "/graph/imgCode?uniqueNo=" +
          localStorage.getItem("udid").toString() +
          "&timestamp=" +
          new Date().getTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$vh_base: 1024;
@function vh($px) {
  @return calc($px / $vh_base) * 100vh;
}
.header {
  width: 100%;
  height: vh(99);
  background: rgba(0, 0, 0, 0.2);
  padding: 0 vh(153) 0 vh(114);
  display: flex;
  align-items: center;
  ::v-deep .el-dialog {
    min-height: 41.2109375vh;
    padding-bottom: vh(10);
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      font-size: vh(23);
      font-weight: bold;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .login_title {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: bold;
        font-size: vh(20);
        line-height: vh(28);
        color: #333333;
        margin-bottom: vh(35);
      }
      .phone_input {
        width: vh(380);
        height: vh(52);
        margin-bottom: vh(35);
        font-size: vh(18);
        .el-input-group__prepend {
          width: vh(70);
          padding: 0;
          background: #f7f7f7;
          text-align: center;
          font-size: vh(19);
        }
        .el-input__inner {
          height: vh(52);
        }
      }
      .code_wrapper {
        margin-bottom: vh(35);
        .code_input {
          width: vh(230);
          height: vh(52);
          margin-right: vh(27);
          font-size: vh(18);
          .el-input-group__prepend {
            width: vh(42);
            padding: 0;
            background: #f7f7f7;
            text-align: center;
            .code_icon {
              width: vh(16.5);
              height: vh(21);
              background-image: url("@/assets/image/pc_home/vertige_icon.png");
              background-repeat: no-repeat;
              background-size: cover;
              display: block;
              margin: 0 auto;
            }
          }
          .el-input__inner {
            height: vh(52);
          }
        }
        .el-button {
          width: vh(119);
          height: vh(52);
          background: #dadada;
          border-radius: vh(3);
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: vh(18);
          line-height: vh(25);
          color: #ffffff;
          padding: 0;
        }
      }
      .verify_input {
        width: vh(380);
        height: vh(52);
        margin-bottom: vh(20);
        font-size: vh(18);
        .el-input-group__append {
          background: #f7f7f7;
          padding: 0;
          img {
            width: vh(120);
            height: vh(40);
          }
        }
        .el-input__inner {
          height: vh(52);
        }
      }
      .login {
        width: vh(180);
        height: vh(52);
        background: #848484;
        border-radius: vh(3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: vh(18);
        color: #ffffff;
        cursor: pointer;
      }
    }
    input {
      &::-webkit-input-placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: vh(18);
      }
    }
  }
  .header_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    .header_left {
      display: flex;
      align-items: center;
      .logo {
        display: flex;
        align-items: center;
        margin: 0 vh(124) 0 0;
        .logo_icon {
          width: vh(61);
          height: vh(61);
          background-image: url("@/assets/image/pc_home/logo.png");
          background-repeat: no-repeat;
          background-size: cover;
          margin: 0 vh(15) 0 0;
        }
        .logo_info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: vh(26);
            color: #ffffff;
          }
          span {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: vh(15);
            color: #ffffff;
          }
        }
      }
      .header_tab {
        display: flex;
        align-items: center;
        span {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: vh(23);
          color: #7e7e7e;
          cursor: pointer;
          font-weight: bold;
          &:first-child {
            margin: 0 vh(100) 0 0;
          }
        }
        .active {
          color: #fff !important;
        }
      }
    }
    .header_userInfo {
      display: flex;
      align-items: center;
      position: relative;
      .userInfo_img {
        width: vh(26);
        height: vh(26);
        border-radius: 50%;
        margin: 0 vh(10) 0 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .userInfo_name {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: vh(18);
        line-height: vh(25);
        color: #ffffff;
        margin: 0 vh(6) 0 0;
        cursor: pointer;
      }
      .userInfo_icon {
        width: vh(14);
        height: vh(8);
        background-image: url("@/assets/image/pc_home/back_icon.png");
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
      }
      &:hover {
        .user_detail {
          display: flex !important;
        }
      }
      .user_detail {
        position: absolute;
        top: vh(26);
        left: vh(39);
        display: none;
        flex-direction: column;
        align-items: center;
        .user_detail_top_icon {
          width: vh(20.8);
          height: vh(13.6);
          background-image: url("@/assets/image/pc_home/login_top_san.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .user_detail_wrapper {
          width: vh(175);
          height: vh(153);
          background: #1195dd;
          box-shadow: 0px vh(2) vh(10) rgba(0, 0, 0, 0.1);
          border-radius: vh(6);
          padding-top: vh(6);
          position: relative;
          top: vh(-2);
          .user_detail_content {
            width: vh(175);
            height: vh(147);
            background: #ffffff;
            box-shadow: 0px vh(2) vh(10) rgba(0, 0, 0, 0.1);
            border-radius: vh(6);
            display: flex;
            flex-direction: column;
            align-items: center;
            .user_detail_img {
              width: vh(48);
              height: vh(48);
              border-radius: 50%;
              border: vh(1) solid #7b7b7b;
              margin-top: vh(12);
              position: relative;
              & > img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
              .user_detail_leval {
                position: absolute;
                top: vh(36);
                width: vh(47);
                height: vh(14);
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .username {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: bold;
              font-size: vh(15);
              line-height: vh(21);
              color: #333333;
              margin: vh(9) 0 vh(10) 0;
            }
            .login_out {
              width: vh(60);
              height: vh(25);
              background: #bfbfbf;
              border-radius: vh(3);
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: vh(12);
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      .user_photo {
        width: vh(26);
        height: vh(26);
        border-radius: 50%;
        margin: 0 vh(10) 0 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      span,
      i {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: vh(18);
      }
      .login {
        color: #fff;
        cursor: pointer;
      }
      i {
        color: #7e7e7e;
      }
      .resgister {
        color: #fff;
        cursor: pointer;
      }
      span {
        &:hover {
          text-shadow: 0 0 0.26667rem #fff, 0 0 0.53333rem #fff, 0 0 0.8rem #fff,
            0 0 1.06667rem #fff;
        }
      }
    }
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: vh(24);
  }
}
</style>