
import { get, post } from "./axiosConfig";
export default {
    getAnchormanInfo: function (payload = {}) {
        return post(payload);
    },
    getAnchormanLive: function (payload = {}) {
        return post(payload);
    },
    anchormanRealData: function (payload = {}) {
        return post(payload);
    },
    getChatroomInfoApi: function (payload = {}) {
        return post(payload);
    },
    smsCodeSend: function (payload = {}) { //验证码发送
        return post(payload);
    },
    memberLogin: function (payload = {}) { //用户登录发送
        return post(payload);
    },
    getMemberInfo: function (payload = {}) { //获取用户信息
        return post(payload);
    },
    getGiftListNew: function (payload = {}) { //获取礼物列表
        return post(payload);
    },
}