<template>
  <div id="app">
    <div class="pc_live" v-if="isRouterPage">
      <pc-header ref="header"></pc-header>
      <router-view :key="$route.fullPath" />
      <div class="footer">
        <div class="footer_logo"></div>
      </div>
    </div>
  </div>
</template>


<script>
import PcHeader from "@/components/PcBar/PcHeader.vue";
export default {
  name: "app",
  provide() {
    return {
      routePageInit: this.routePageInit,
      loginFun: this.loginFun,
    };
  },
  data() {
    return {
      isRouterPage: true,
    };
  },
  components: {
    PcHeader,
  },
  created() {
    this.getGiftList();
  },
  methods: {
    routePageInit() {
      this.isRouterPage = false;
      this.$nextTick(() => {
        this.isRouterPage = true;
      });
    },
    loginFun() {
      this.$refs.header.openLogin();
    },
    async getGiftList() {
      //获取礼物列表
      let res = await this.$homeApi.getGiftListNew({
        apiName: "getGiftListNew",
      });
      if (res.code == "1" && res.data) {
        this.$store.commit("SET_LIST_GIFT", res.data.listGift);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$vh_base: 1024;
@function vh($px) {
  @return calc($px / $vh_base) * 100vh;
}
html,
body {
  height: 100%;
  padding: 0;
}
#app {
  width: 100vw;
  height: 100vh;
  background-image: url("@/assets/image/pc_home/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .pc_live {
    background: rgba(0, 0, 0, 0.5);
  }
}
.footer {
  width: 100%;
  height: calc(100vh - 9.66796875vh - 72.94921875vh);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 26.25%
  );
  .footer_logo {
    width: vh(375);
    height: vh(131);
    margin: 0 auto;
    background-image: url("@/assets/image/pc_home/footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: vh(16);
  }
}
</style>

