import { uuid } from 'vue3-uuid';
import axios from 'axios';
axios.defaults.timeout = 500000;
const domain = process.env.NODE_ENV_BASEURL;
let UUID = ''
if (localStorage.getItem('udid') == null) {
  UUID = uuid.v4()
  localStorage.setItem('udid', UUID)
} else {
  UUID = localStorage.getItem('udid').toString()
}
window.baseAddress = "http://api.68f4deb.com/";//用于验证码地址
axios.interceptors.request.use(config => {
  let BaseDate = {
    timestamp: new Date().getTime(),
    platform: 4,
    channel: 1,
    vt: '3.0',
    uniqueNo: UUID,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
  }
  config.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  config.data = { ...config.data, ...BaseDate }
  return config;

}, error => {

  return Promise.reject(error);

});

axios.interceptors.response.use(response => {

  const { data, status } = response;

  if (status != 200)
    if (data.code == 401) {



    } else if (data.code == 999) {
      // token无效

    }
    else if (data.code == 1000) {

    }
    else {

    }

  return response.data;

}, error => {

  console.log(error)

});

export function get(url, params, header = {}, prefix = true) {
  return new Promise((resolve, reject) => {
    axios.get(prefix ? `${domain}${url}` : `${url}`, {
      params: params
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function post(data) {
  return new Promise((resolve, reject) => {
    axios.post(`${domain}`, data).then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}
