//公共js，主要做表单验证，以及基本方法封装
const utils = {
    isNullOrEmpty: function (value) {
        //是否为空
        return (value === null || value === '' || value === undefined) ? true : false;
    },
    trim: function (value) {
        //去空格
        return value.replace(/(^\s*)|(\s*$)/g, "");
    },
    isMobile: function (value) {
        //是否为手机号
        return /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/.test(value);
    },
    isFloat: function (value) {
        //金额，只允许保留两位小数
        return /^([0-9]*[.]?[0-9])[0-9]{0,1}$/.test(value);
    },
    isNum: function (value) {
        //是否全为数字
        return /^[0-9]+$/.test(value);
    },
    checkPwd: function (value) {
        //密码为8~20位数字和字母组合
        return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(value);
    },
    formatNum: function (num) {
        //格式化手机号码
        if (utils.isMobile(num)) {
            num = num.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
        }
        return num;
    },
    verificatCode: function (num) {
        //6位验证码
        return num.length == 6;
    },
    rmoney: function (money) {
        //金额格式化
        return parseFloat(money).toFixed(2).toString().split('').reverse().join('').replace(/(\d{3})/g, '$1,').replace(
            /\,$/, '').split('').reverse().join('');
    },
    formatTime: function (time) {
        if (typeof time !== 'number' || time < 0) {
            return time
        }
        var hour = parseInt(time / 3600)
        time = time % 3600
        var minute = parseInt(time / 60)
        time = time % 60
        var second = time

        return ([hour, minute, second]).map(function (n) {
            console.log(n, n[1])
            n = n.toString()
            return n[1] ? n : '0' + n
        }).join(':')
    },
    //录音时间返回
    recordTime: function (time) {
        if (typeof time !== 'number' || time < 0) {
            return time
        }
        var hour = parseInt(time / 3600)
        time = time % 3600
        var minute = parseInt(time / 60)
        time = time % 60
        var second = time

        return ([minute, second]).map(function (n) {
            n = n.toString()
            return n[1] ? n : '0' + n
        }).join(':')
    },
}

module.exports = {
    isNullOrEmpty: utils.isNullOrEmpty,
    recordTime: utils.recordTime,
    trim: utils.trim,
    isMobile: utils.isMobile,
    isFloat: utils.isFloat,
    isNum: utils.isNum,
    checkPwd: utils.checkPwd,
    formatNum: utils.formatNum,
    rmoney: utils.rmoney,
    formatDate: utils.formatDate,
    formatTime: utils.formatTime,
    verificatCode: utils.verificatCode
}