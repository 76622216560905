import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/reset.css'  // 引入基本样式
import 'lib-flexible/flexible'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import homeApi from './http/homeApi';
import md5 from 'js-md5';
Vue.prototype.$homeApi = homeApi
Vue.prototype.verifyStr = function (apiName) { //获取验证字符串
  let platform = 4;
  let timestamp = new Date().getTime();
  return md5(apiName + platform + timestamp);
};
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


