const state = () => ({
    token: null, //用户token信息
    userInfoData: {}, //用户个人信息
    listGift: [],    //礼物列表
    watchFun: false // 是否调用过一次初始化视频
});

const mutations = {
    M_TOKEN(state, val) {
        state.token = val
    },
    SET_USER_DATA(state, val) {
        state.userInfoData = val
    },
    SET_LIST_GIFT(state, value) {
        state.listGift = value;
    },
    SET_WATCH_FUN(state, bool) {
        state.watchFun = bool;
    },
};

const actions = {

};

export default {
    state,
    mutations,
    actions
}
